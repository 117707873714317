import { createSlice } from '@reduxjs/toolkit';
import {fetchInstallPackagesData ,fetchInstallPackagesDataFromMongo } from './installedPackagesAction';

const installPackagesSlice = createSlice({
  name: 'installPackages',
  initialState: {
    installPackagesList: [],
    installPackagesListFromMongo:[],
    loading: 'idle',
    loadingFromMongo:'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstallPackagesData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchInstallPackagesData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.installPackagesList = action.payload;
      })
      .addCase(fetchInstallPackagesData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchInstallPackagesDataFromMongo.pending, (state) => {
        state.loadingFromMongo = 'pending';
      })
      .addCase(fetchInstallPackagesDataFromMongo.fulfilled, (state, action) => {
        state.loadingFromMongo = 'fulfilled';
        state.installPackagesListFromMongo = action.payload;
      })
      .addCase(fetchInstallPackagesDataFromMongo.rejected, (state, action) => {
        state.loadingFromMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default installPackagesSlice.reducer;

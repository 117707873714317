import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchSalesforceCommunityData = createAsyncThunk(
  "salesforceCommunity/fetchSalesforceCommunityData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
    const response = await fetch(
      ` /v1/salesforce/salesforcecommunities/fetch/all`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          gid:gid,
          at:at,
          iu:iu
        },
      }
    );
    const data = await response.json();
    // console.log('fetchSalesforceCommunityData',data);
    return data;
  }
);
export const fetchSalesforceCommunityDataFromMongo = createAsyncThunk(
  "salesforceCommunity/fetchSalesforceCommunityDatafromMongo",
  async () => {
    const guid=window.sessionStorage.getItem('guid');
    const response = await fetch(
      ` /v1/salesforce/salesforcecommunities/${guid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    // console.log('fetchSalesforceCommunityData',data);
    return data;
  }
);
import { createSlice } from '@reduxjs/toolkit';
import { fetchUserData ,fetchUserDataFromMongo  } from './userActions';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userList: [],
    userListFromMongo:[],
    loading: 'idle',
    loadingfromMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.userList = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchUserDataFromMongo.pending, (state) => {
        state.loadingfromMongo = 'pending';
      })
      .addCase(fetchUserDataFromMongo.fulfilled, (state, action) => {
        state.loadingfromMongo = 'fulfilled';
        state.userListFromMongo = action.payload;
      })
      .addCase(fetchUserDataFromMongo.rejected, (state, action) => {
        state.loadingfromMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;

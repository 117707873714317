import { createSlice } from '@reduxjs/toolkit';
import {fetchSalesforceCommunityData ,fetchSalesforceCommunityDataFromMongo } from './salesforceCommunityAction';

const salesforceCommunitySlice = createSlice({
  name: 'salesforceCommunity',
  initialState: {
    salesforceCommunityList: [],
    salesforceCommunityListFromMongo: [],
    loading: 'idle',
    loadingforMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesforceCommunityData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchSalesforceCommunityData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.salesforceCommunityList = action.payload;
      })
      .addCase(fetchSalesforceCommunityData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchSalesforceCommunityDataFromMongo.pending, (state) => {
        state.loadingforMongo = 'pending';
      })
      .addCase(fetchSalesforceCommunityDataFromMongo.fulfilled, (state, action) => {
        state.loadingforMongo = 'fulfilled';
        state.salesforceCommunityListFromMongo = action.payload;
      })
      .addCase(fetchSalesforceCommunityDataFromMongo.rejected, (state, action) => {
        state.loadingforMongo = 'rejected';
        state.error = action.error.message;
      })
  },
});

export default salesforceCommunitySlice.reducer;

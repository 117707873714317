import { createAsyncThunk } from "@reduxjs/toolkit";

// console.log('fetchCodeQualityAbove',fetchdata);
export const fetchTestCoverageData = createAsyncThunk(
  "testQuality/fetchTestCoverageData",
  async () => {
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
      const response = await fetch(
        ` /v1/salesforce/codecoverage/fetch/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            at:at,
            iu:iu
          },
        }
      );
      const data = await response.json();
   
      // console.log('fetchCodeQualityData',data);
      return data;
    }
  
);
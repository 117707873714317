import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchRoleData = createAsyncThunk(
  "role/fetchRoleData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
    const response = await fetch(` /v1/salesforce/role/fetch/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        gid:gid,
        at:at,
        iu:iu
      },
    });
    const data = await response.json();
    console.log("fetchRoleData 123 ", data);
    return data;
  }
);

export const fetchRoleDataFromMogo = createAsyncThunk(
  "roleGuid/fetchRoleDataFromMogo",
  async () => {
    try {
      const guid=window.sessionStorage.getItem('guid');
      const response = await fetch(` /v1/salesforce/role/${guid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  }
);
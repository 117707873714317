import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchStaticResourcesData = createAsyncThunk(
  "staticResources/fetchStaticResourcesData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
      const response = await fetch(
        ` /v1/salesforce/staticresource/fetch/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            gid:gid,
            at:at,
            iu:iu
          },
        }
      );
      const data = await response.json();
      // console.log('fetchStaticResourcesData',data);
      return data;
    
  }
);
export const fetchStaticResourcesDataFromMongo = createAsyncThunk(
  "staticResources/fetchStaticResourcesDataFromMonog",
  async () => {
    const guid=window.sessionStorage.getItem('guid');
      const response = await fetch(
        ` /v1/salesforce/staticresource/${guid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
      
          },
        }
      );
      const data = await response.json();
      // console.log('fetchStaticResourcesData',data);
      return data;
    
  }
);
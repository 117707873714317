import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminData , fetchAdminDataFromMongo } from './adminAction';

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    username: [],
    usernameFromMongo: [],
    loading: 'idle',
    loadingForMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchAdminData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.username = action.payload;
      })
      .addCase(fetchAdminData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchAdminDataFromMongo.pending, (state) => {
        state.loadingForMongo = 'pending';
      })
      .addCase(fetchAdminDataFromMongo.fulfilled, (state, action) => {
        state.loadingForMongo = 'fulfilled';
        state.usernameFromMongo = action.payload;
      })
      .addCase(fetchAdminDataFromMongo.rejected, (state, action) => {
        state.loadingForMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default adminSlice.reducer;

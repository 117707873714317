import { createSlice } from "@reduxjs/toolkit";
import { fetchRoleData, fetchRoleDataFromMogo } from "./roleActions";

const roleSlice = createSlice({
  name: "role",
  initialState: {
    roleData: [],
    loading: "idle",
    error: null,
    roleDataFromMogo: [],
    loadingFromMogo: "idle",
    errorFromMogo: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleData.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchRoleData.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.roleData = action.payload;
      })
      .addCase(fetchRoleData.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      })
      .addCase(fetchRoleDataFromMogo.pending, (state) => {
        state.loadingFromMogo = "pending";
      })
      .addCase(fetchRoleDataFromMogo.fulfilled, (state, action) => {
        state.loadingFromMogo = "fulfilled";
        state.roleDataFromMogo = action.payload;
      })
      .addCase(fetchRoleDataFromMogo.rejected, (state, action) => {
        state.loadingFromMogo = "rejected";
        state.error = action.error.message;
      });
  },
});

export default roleSlice.reducer;
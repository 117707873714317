import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchAuthData = createAsyncThunk(
  "auth/fetchAuthData",
  async () => {
    const guid=    window.sessionStorage.getItem("guid");
    console.log( "Ac in guid",guid);
    const response = await fetch(`/v1/salesforce/authCheck/${guid}`);
    const data = await response.json();

    window.sessionStorage.setItem("connected", data.connected);
    window.sessionStorage.setItem("instaceurl", data.instanceUrl);
    window.sessionStorage.setItem("accessToken", data.accessToken);
    console.log( "Ac in web",window.sessionStorage.getItem("accessToken"));
    
    console.log("instance url in web ",window.sessionStorage.getItem("instaceurl"));


 console.log(window.sessionStorage.getItem("guid"));
    return data;
  }
);
import { createSlice } from '@reduxjs/toolkit';
import { fetchUserLicenseData,fetchUserLicenseDataFromMongo } from './userLicenseActions';

const userLicenseSlice = createSlice({
  name: 'userLicense',
  initialState: {
    userLicenseList: [],
    userLicenseListFromMongo: [],
    loading: 'idle',
    loadingForMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserLicenseData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchUserLicenseData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.userLicenseList = action.payload;
        // console.log('userLicenseList reducer',fetchUserLicenseData.fulfilled);
      })
      .addCase(fetchUserLicenseData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchUserLicenseDataFromMongo.pending, (state) => {
        state.loadingForMongo = 'pending';
      })
      .addCase(fetchUserLicenseDataFromMongo.fulfilled, (state, action) => {
        state.loadingForMongo = 'fulfilled';
        state.userLicenseListFromMongo = action.payload;
        // console.log('userLicenseList reducer',fetchUserLicenseData.fulfilled);
      })
      .addCase(fetchUserLicenseDataFromMongo.rejected, (state, action) => {
        state.loadingForMongo = 'rejected';
        state.error = action.error.message;
      })
  },
 
});


export default userLicenseSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import {fetchEmailTemplateData ,fetchEmailTemplateDataFromMongo} from './emailTemplateAction';

const emailTemplateSlice = createSlice({
  name: 'emailTemplate',
  initialState: {
    emailTemplateList: [],
    emailTemplateListFromMongo:[],
    loading: 'idle',
    loadingMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmailTemplateData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchEmailTemplateData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.emailTemplateList = action.payload;
      })
      .addCase(fetchEmailTemplateData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchEmailTemplateDataFromMongo.pending, (state) => {
        state.loadingMongo = 'pending';
      })
      .addCase(fetchEmailTemplateDataFromMongo.fulfilled, (state, action) => {
        state.loadingMongo = 'fulfilled';
        state.emailTemplateListFromMongo = action.payload;
      })
      .addCase(fetchEmailTemplateDataFromMongo.rejected, (state, action) => {
        state.loadingMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default emailTemplateSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCustomObjectData = createAsyncThunk(
  "customObject/fetchCustomObjectData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
      const response = await fetch(
        ` /v1/salesforce/object/fetch/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            gid:gid,
            at:at,
            iu:iu
          },
        }
      );
      const data = await response.json();
      return data;
    }
  
);
export const fetchCustomObjectDataFromMongo = createAsyncThunk(
  "customObject/fetchCustomObjectDataFromMongo",
  async () => {
    const guid=window.sessionStorage.getItem('guid');
      const response = await fetch(
        ` /v1/salesforce/object/${guid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      return data;
    }
  
);
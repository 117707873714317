import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCustomLabelData = createAsyncThunk(
  "customLabel/fetchCustomLabelData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
    const response = await fetch(` /v1/salesforce/customlabel/fetch/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        gid:gid,
        at:at,
        iu:iu
      },
    });
    const data = await response.json();
    //      // console.log('fetchCustomLabelData',data);
    return data;
  }
);
export const fetchCustomLabelDataFromMogo = createAsyncThunk(
  "customLabel/fetchCustomLabelDataFromMongo",
  async () => {
    const guid=window.sessionStorage.getItem('guid');
    const response = await fetch(` /v1/salesforce/customlabel/${guid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    //      // console.log('fetchCustomLabelData',data);
    return data;
  }
);

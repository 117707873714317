import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchProfileData = createAsyncThunk(
  "profile/fetchProfileData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
    const response = await fetch(` /v1/salesforce/profile/fetch/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        gid:gid,
        at:at,
        iu:iu
      },
    });
    const data = await response.json();

    // console.log('profile',data)
    return data;
  }
);

export const fetchProfileDataFromMogo = createAsyncThunk(
  "profile/fetchProfileDataFromMogo",
  async () => {
    try {
      const guid=window.sessionStorage.getItem('guid');
      const response = await fetch(` /v1/salesforce/profile/${guid}`, {
        //send in guid
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // service_ref: window.sessionStorage.getItem("orgid"),
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  }
);
import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";
import Auth from "./Authentication/auth";
import { Provider } from "react-redux";
import store from "./store";

// Dashboard
const Landingpageapp = React.lazy(() => import("./components/Landingpageapp"));
// other pages
const UserPage = React.lazy(() => import("./components/User&Licenses/UserPage"));
const MetadataPage = React.lazy(() => import("./components/Metadata/MetadataPage"));
const SecurityPage = React.lazy(() => import("./components/Security/SecurityPage"));
const CodeQualityPage = React.lazy(() => import("./components/CodeQuality/CodeQualityPage"));
const ErrorsPage = React.lazy(() => import("./components/Errors/ErrorsPage"))

// import UserPage from "./components/User&Licenses/UserPage";
// import MetadataPage from "./components/Metadata/MetadataPage" ;
// import SecurityPage from "./components/Security/SecurityPage";
// import CodeQualityPage from "./components/CodeQuality/CodeQualityPage";
// import ErrorsPage from "./components/Errors/ErrorsPage";

const Dashboard = React.lazy(() => import("./components/Dashboard/DashboardPage"))
const App = React.lazy(() => import("./components/app"));
const Error404 = React.lazy(() => import("./components/Custompages/Error1-404/Error-404"))
const AuthLogin = React.lazy(() => import("./Authentication/Login"));
// const AuthSignup = React.lazy(() => import("./Authentication/Signup"))
const Root = () => {
  return (
<Provider store={store}>
    <Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
          <Route path={`/`} element={<Auth />}>
            <Route index element={<AuthLogin />} />
            <Route
              path={`/login`}
              element={<AuthLogin />}
            />
            </Route>
            <Route path={`/`}
              element={<App />}>
              <Route>
                <Route
                  path={`/dashboard`}
                  element={<Dashboard />}
                />
                <Route
                  path={`/users`}
                  element={<UserPage />}
                />
                <Route
                  path={`/metadata`}
                  element={<MetadataPage />}
                />
                <Route
                  path={`/security`}
                  element={<SecurityPage />}
                />
                <Route
                  path={`/codequality`}
                  element={<CodeQualityPage />}
                />
                <Route
                  path={`/errors`}
                  element={<ErrorsPage />}
                /> 
              </Route>

              </Route>
            <Route path={`/landingpage`} element={<Landingpageapp />} />
            {/* ........................................Errorpage............................................... */}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </Fragment>
    </Provider>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchPdfData = createAsyncThunk('pdf/fetchPdfData', async () => {
    
  const guid=window.sessionStorage.getItem('guid');
    const response = await fetch(`/v1/salesforce/generatepdf/emailOnPersonalMail/${guid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // console.log('fetchPdfData', response);
    const responseData = await response.json();
    return responseData;

});

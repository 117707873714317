import { createSlice } from '@reduxjs/toolkit';
import { fetchLwcAuraData } from './LwcAuraAction';

const lwcAuraSlice = createSlice({
  name: 'lwcAura',
  initialState: {
    lwcAuralist: [],
    loading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLwcAuraData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchLwcAuraData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.lwcAuralist = action.payload;
      })
      .addCase(fetchLwcAuraData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default lwcAuraSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import {fetchPdfData} from './PdfAction';

const pdfSlice = createSlice({
  name: 'pdf',
  initialState: {
    pdfData: [],
    loading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPdfData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchPdfData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.pdfData = action.payload;
      })
      .addCase(fetchPdfData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default pdfSlice.reducer;
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchUserLicenseData = createAsyncThunk(
  "userLicense/fetchUserLicenseData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
    const response = await fetch(` /v1/salesforce/userlicense/fetch/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        gid:gid,
        at:at,
        iu:iu
      },
    });
    const data = await response.json();

    // console.log('fetchUserLicenseData', data);
    return data;
  }
);
export const fetchUserLicenseDataFromMongo = createAsyncThunk(
  "userLicense/fetchUserLicenseDataFromMongo",
  async () => {
    const guid=window.sessionStorage.getItem('guid');
    const response = await fetch(` /v1/salesforce/userlicense/${guid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    // console.log('fetchUserLicenseData', data);
    return data;
  }
);

import { createSlice } from '@reduxjs/toolkit';
import {fetchAuthData} from './authAction';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authDetails: [],
    loading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchAuthData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.authDetails = action.payload;
      })
      .addCase(fetchAuthData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default authSlice.reducer;

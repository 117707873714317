import { createSlice } from "@reduxjs/toolkit";
 import { fetchProfileData, fetchProfileDataFromMogo } from "./profileActions";


const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profilesDetails: [],
    loading: "idle",
    error: null,
    profilesDetailsFromMogo: [],
    loadingFromMogo: "idle",
    errorFromMogo: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileData.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchProfileData.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.profilesDetails = action.payload;
      })
      .addCase(fetchProfileData.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      })
      .addCase(fetchProfileDataFromMogo.pending, (state) => {
        state.loadingFromMogo = "pending";
      })
      .addCase(fetchProfileDataFromMogo.fulfilled, (state, action) => {
        state.loadingFromMogo = "fulfilled";
        state.profilesDetailsFromMogo = action.payload;
      })
      .addCase(fetchProfileDataFromMogo.rejected, (state, action) => {
        state.loadingFromMogo = "rejected";
        state.error = action.error.message;
      });
  },
});

export default profileSlice.reducer;
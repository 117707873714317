import { createSlice } from '@reduxjs/toolkit';
import { fetchPageLayoutData ,fetchPageLayoutDataFromMongo } from './pagelayoutAction';

const pageLayoutSlice = createSlice({
  name: 'pageLayout',
  initialState: {
    pageLayoutList: [],
    pageLayoutListFromMongo: [],
    loading: 'idle',
    loadingForMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPageLayoutData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchPageLayoutData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.pageLayoutList = action.payload;
      })
      .addCase(fetchPageLayoutData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchPageLayoutDataFromMongo.pending, (state) => {
        state.loadingForMongo = 'pending';
      })
      .addCase(fetchPageLayoutDataFromMongo.fulfilled, (state, action) => {
        state.loadingForMongo = 'fulfilled';
        state.pageLayoutListFromMongo = action.payload;
      })
      .addCase(fetchPageLayoutDataFromMongo.rejected, (state, action) => {
        state.loadingForMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default pageLayoutSlice.reducer;

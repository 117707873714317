import { combineReducers } from "redux"

import userReducer from './userPagecontent/userReducer';
import userLicenseReducer from './userPagecontent/userLicenseReducer';
import permissionSetReducer from './userPagecontent/permissionSetReducer';
import profileReducer from './userPagecontent/profileReducer';
import roleReducer from './userPagecontent/roleReducer';
import reportReducer from './dashboardPagecontent/reportReducer';
import orgLimitReducer from './dashboardPagecontent/orgLimitReducer';

import securityReducer from './SecurityPageContent/securityReducer';

import customObjectReducer from './MetaDataPageContent/customObjectReducer';
import dashboardReducer from './MetaDataPageContent/dashboardReducer';
import emailTemplateReducer from './MetaDataPageContent/emailTemplateReducer';
import staticResourcesReducer from './MetaDataPageContent/staticResourcesReducer';
import installPackagesReducer from './MetaDataPageContent/installPackagesReducer';
import remoteSitesReducer from './MetaDataPageContent/remoteSitesReducer';
import salesforceCommunityReducer from './MetaDataPageContent/salesforceCommunityReducer';
import processBuilderReducer from './MetaDataPageContent/processBuilderReducer';
import salesforceSitesReducer from './MetaDataPageContent/salesforceSitesReducer';
import customLabelReducer from './MetaDataPageContent/customLabelReducer';
import ValidationRuleReducer from './MetaDataPageContent/ValidationRuleReducer';
import recordTypeReducer from './MetaDataPageContent/recordTypeReducer';
import pagelayoutReducer from './MetaDataPageContent/pagelayoutReducer';

import CodeCoveragereducer from "./dashboardPagecontent/CodeCoveragereducer"
import adminReducer from "./dashboardPagecontent/adminReducer"
import LwcAuraReducer from "./CodeQualityPageContent/LwcAuraReducer"
import authReducer from "./dashboardPagecontent/authReducer"
import PdfReducer from "./dashboardPagecontent/PdfReducer"
import testCoverageReducer from "./CodeQualityPageContent/testCoverageReducer";
import LeadCreateReducer from "./dashboardPagecontent/LeadCreateReducer";

const rootReducer = combineReducers({
  report: reportReducer,
  orgLimit :orgLimitReducer,
  user: userReducer,
  permissionSet: permissionSetReducer,
  profile: profileReducer,
  role: roleReducer,
  userLicense: userLicenseReducer,
  security: securityReducer,
  customObject: customObjectReducer,
  dashboard : dashboardReducer,
  emailTemplate: emailTemplateReducer,
  staticResources: staticResourcesReducer,
  installPackages: installPackagesReducer,
  remoteSites: remoteSitesReducer,
  salesforceCommunity: salesforceCommunityReducer,
  processBuilder: processBuilderReducer,
  SalesforceSites: salesforceSitesReducer,
  customLabel: customLabelReducer,
  codeCoverage: CodeCoveragereducer,
  admin : adminReducer,
  lwcAura : LwcAuraReducer,
  pageLayout : pagelayoutReducer,
  recordType : recordTypeReducer,
  validationRule : ValidationRuleReducer,
  auth : authReducer,
  pdf : PdfReducer,
  testQuality : testCoverageReducer,
  Lead :LeadCreateReducer,

})

export default rootReducer


import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchOrgLimitData = createAsyncThunk(
  "orgLimit/fetchOrgLimitData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
      const response = await fetch(
        ` /v1/salesforce/filestorage/fetch/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          gid:gid,
          at:at,
          iu:iu
          },
        }
      );
      const data = await response.json();
      return data;
    }
  
);

export const fetchOrgLimitDatafromMongo = createAsyncThunk(
  "orgLimit/fetchOrgLimitDataFromMongo",
  async () => {
    const guid=window.sessionStorage.getItem('guid');
      const response = await fetch(
        ` /v1/salesforce/filestorage/${guid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      return data;
    }
  
);

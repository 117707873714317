import { createSlice } from '@reduxjs/toolkit';
import { fetchCodeCoverageData, fetchCodeCoverageFromMongo } from './CodecoverageAction';

const codeCoverageSlice = createSlice({
  name: 'codeCoverage',
  initialState: {
    codeCoverageListData: [],
    codeCoverageListMongo:[],
    CodeCovgloading: 'idle',
    CodeCovgloadingMongo:"idle",
    error: null,
    errorMongo: null, // Add a separate error state for MongoDB
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCodeCoverageData.pending, (state) => {
        state.CodeCovgloading = 'pending';
      })
      .addCase(fetchCodeCoverageData.fulfilled, (state, action) => {
        state.CodeCovgloading = 'fulfilled';
        state.codeCoverageListData = action.payload;
      })
      .addCase(fetchCodeCoverageData.rejected, (state, action) => {
        state.CodeCovgloading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchCodeCoverageFromMongo.pending, (state) => {
        state.CodeCovgloadingMongo = 'pending';
      })
      .addCase(fetchCodeCoverageFromMongo.fulfilled, (state, action) => {
        state.CodeCovgloadingMongo = 'fulfilled';
        state.codeCoverageListMongo = action.payload;
      })
      .addCase(fetchCodeCoverageFromMongo.rejected, (state, action) => {
        state.CodeCovgloadingMongo = 'rejected';
        state.errorMongo = action.error.message; // Separate error state for MongoDB
      });
  },
});

export default codeCoverageSlice.reducer;

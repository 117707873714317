import { createSlice } from '@reduxjs/toolkit';
import {fetchCustomLabelData ,fetchCustomLabelDataFromMogo } from './customLabelAction';

const customLabelSlice = createSlice({
  name: 'customLabel',
  initialState: {
    customLabelList: [],
    customLabelListFromMongo: [],
    loading: 'idle',
    loadingForMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomLabelData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchCustomLabelData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.customLabelList = action.payload;
      })
      .addCase(fetchCustomLabelData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchCustomLabelDataFromMogo.pending, (state) => {
        state.loadingForMongo = 'pending';
      })
      .addCase(fetchCustomLabelDataFromMogo.fulfilled, (state, action) => {
        state.loadingForMongo = 'fulfilled';
        state.customLabelListFromMongo = action.payload;
      })
      .addCase(fetchCustomLabelDataFromMogo.rejected, (state, action) => {
        state.loadingForMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default customLabelSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import {fetchDashboardData,fetchDashboardDataFromMongo} from './dashboardAction';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboardListData: [],
    dashboardDataFromMongo:[],
    loading: 'idle',
    loadingMongo:'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.dashboardListData = action.payload;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      });
      builder
      .addCase(fetchDashboardDataFromMongo.pending, (state) => {
        state.loadingMongo = 'pending';
      })
      .addCase(fetchDashboardDataFromMongo.fulfilled, (state, action) => {
        state.loadingMongo = 'fulfilled';
        state.dashboardDataFromMongo = action.payload;
      })
      .addCase(fetchDashboardDataFromMongo.rejected, (state, action) => {
        state.loadingMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default dashboardSlice.reducer;

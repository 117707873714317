import { createSlice } from '@reduxjs/toolkit';
import {fetchStaticResourcesData,fetchStaticResourcesDataFromMongo} from './staticResourcesAction';

const customObjectSlice = createSlice({
  name: 'staticResources',
  initialState: {
    staticResourcesListData: [],
    staticResourceDataFromMongo:[],
    loading: 'idle',
    loadingMongo:'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStaticResourcesData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchStaticResourcesData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.staticResourcesListData = action.payload;
      })
      .addCase(fetchStaticResourcesData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchStaticResourcesDataFromMongo.pending, (state) => {
        state.loadingMongo = 'pending';
      })
      .addCase(fetchStaticResourcesDataFromMongo.fulfilled, (state, action) => {
        state.loadingMongo = 'fulfilled';
        state.staticResourceDataFromMongo = action.payload;
      })
      .addCase(fetchStaticResourcesDataFromMongo.rejected, (state, action) => {
        state.loadingMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default customObjectSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { fetchValidationRuleData ,fetchValidationRuleDataFromMongo} from './validationRuleAction';

const validationRuleSlice = createSlice({
  name: 'validationRule',
  initialState: {
    validationRuleList: [],
    validationRuleListFromMongo: [],
    loading: 'idle',
    loadingForMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchValidationRuleData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchValidationRuleData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.validationRuleList = action.payload;
      })
      .addCase(fetchValidationRuleData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
       .addCase(fetchValidationRuleDataFromMongo.pending, (state) => {
        state.loadingForMongo = 'pending';
      })
      .addCase(fetchValidationRuleDataFromMongo.fulfilled, (state, action) => {
        state.loadingForMongo = 'fulfilled';
        state.validationRuleListFromMongo = action.payload;
      })
      .addCase(fetchValidationRuleDataFromMongo.rejected, (state, action) => {
        state.loadingForMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default validationRuleSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
;
export const fetchPermissionSetData = createAsyncThunk(
  "permissionSet/fetchPermissionSetData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
      const response = await fetch(
        ` /v1/salesforce/permissionset/fetch/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            gid:gid,
            at:at,
            iu:iu
          },
        }
      );
      const data = await response.json();
      return data;
    
  }
);
export const fetchPermissionSetDataFromMongo = createAsyncThunk(
  "permissionSet/fetchPermissionSetDataFromMongo",
  async () => {
    const guid=window.sessionStorage.getItem('guid');
      const response = await fetch(
        ` /v1/salesforce/permissionset/${guid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
      
          },
        }
      );
      const data = await response.json();
      return data;
    
  }
);

import { createSlice } from '@reduxjs/toolkit';
import {fetchOrgLimitData ,fetchOrgLimitDatafromMongo } from './orgLimitActions';

const orgLimitSlice = createSlice({
  name: 'orgLimit',
  initialState: {
    orgLimitList: [],
    orgLimitListFromMongo: [],
    loading: 'idle',
    loadingFroMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrgLimitData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchOrgLimitData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.orgLimitList = action.payload;
      })
      .addCase(fetchOrgLimitData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchOrgLimitDatafromMongo.pending, (state) => {
        state.loadingFroMongo = 'pending';
      })
      .addCase(fetchOrgLimitDatafromMongo.fulfilled, (state, action) => {
        state.loadingFroMongo = 'fulfilled';
        state.orgLimitListFromMongo = action.payload;
      })
      .addCase(fetchOrgLimitDatafromMongo.rejected, (state, action) => {
        state.loadingFroMongo = 'rejected';
        state.error = action.error.message;
      })

  },
});

export default orgLimitSlice.reducer;

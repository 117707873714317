import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
      const response = await fetch(` /v1/salesforce/user/fetch/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          gid:gid,
          at:at,
          iu:iu
        },
      });
      const data = await response.json();
      return data;
    }
  
);

export const fetchUserDataFromMongo = createAsyncThunk(
  "user/fetchUserDataFromMongo",
  async () => {
    const guid=window.sessionStorage.getItem('guid');
      const response = await fetch(` /v1/salesforce/user/${guid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      return data;
    }
  
);


import { createSlice } from '@reduxjs/toolkit';
import {fetchRemoteSitesData ,fetchRemoteSitesDataFromMongo } from './remoteSitesAction';

const remoteSitesSlice = createSlice({
  name: 'remoteSites',
  initialState: {
    remoteSitesList: [],
    remoteSiteFromMongo:[],
    loading: 'idle',
    loadingFromMongo:'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRemoteSitesData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchRemoteSitesData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.remoteSitesList = action.payload;
      })
      .addCase(fetchRemoteSitesData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchRemoteSitesDataFromMongo.pending, (state) => {
        state.loadingFromMongo = 'pending';
      })
      .addCase(fetchRemoteSitesDataFromMongo.fulfilled, (state, action) => {
        state.loadingFromMongo = 'fulfilled';
        state.remoteSiteFromMongo = action.payload;
      })
      .addCase(fetchRemoteSitesDataFromMongo.rejected, (state, action) => {
        state.loadingFromMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default remoteSitesSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import {fetchProcessBuilderData ,fetchProcessBuilderDataFromMongo } from './processBuilderAction';

const processBuilderSlice = createSlice({
  name: 'processBuilder',
  initialState: {
    processBuilderList: [],
    processBuilderListFromMongo:[],
    loading: 'idle',
    loadingFromMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProcessBuilderData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchProcessBuilderData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.processBuilderList = action.payload;
      })
      .addCase(fetchProcessBuilderData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchProcessBuilderDataFromMongo.pending, (state) => {
        state.loadingFromMongo = 'pending';
      })
      .addCase(fetchProcessBuilderDataFromMongo.fulfilled, (state, action) => {
        state.loadingFromMongo = 'fulfilled';
        state.processBuilderListFromMongo = action.payload;
      })
      .addCase(fetchProcessBuilderDataFromMongo.rejected, (state, action) => {
        state.loadingFromMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default processBuilderSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import {fetchCustomObjectData ,fetchCustomObjectDataFromMongo } from './customObjectAction';

const customObjectSlice = createSlice({
  name: 'customObject',
  initialState: {
    customObjectList: [],
    customObjectListFromMongo: [],
    loading: 'idle',
    loadingForMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomObjectData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchCustomObjectData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.customObjectList = action.payload;
      })
      .addCase(fetchCustomObjectData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchCustomObjectDataFromMongo.pending, (state) => {
        state.loadingForMongo = 'pending';
      })
      .addCase(fetchCustomObjectDataFromMongo.fulfilled, (state, action) => {
        state.loadingForMongo = 'fulfilled';
        state.customObjectListFromMongo = action.payload;
      })
      .addCase(fetchCustomObjectDataFromMongo.rejected, (state, action) => {
        state.loadingForMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});
export default customObjectSlice.reducer;

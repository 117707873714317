import { createSlice } from '@reduxjs/toolkit';
import { fetchRecordTypeData ,fetchRecordTypeDataFromMongo} from './recordTypeAction';

const recordTypeSlice = createSlice({
  name: 'recordType',
  initialState: {
    recordTypeList: [],
    recordTypeListFromMongo: [],
    loading: 'idle',
    loadingForMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecordTypeData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchRecordTypeData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.recordTypeList = action.payload;
      })
      .addCase(fetchRecordTypeData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchRecordTypeDataFromMongo.pending, (state) => {
        state.loadingForMongo = 'pending';
      })
      .addCase(fetchRecordTypeDataFromMongo.fulfilled, (state, action) => {
        state.loadingForMongo = 'fulfilled';
        state.recordTypeListFromMongo = action.payload;
      })
      .addCase(fetchRecordTypeDataFromMongo.rejected, (state, action) => {
        state.loadingForMongo = 'rejected';
        state.error = action.error.message;
      })
  },
});

export default recordTypeSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import {fetchPermissionSetData ,fetchPermissionSetDataFromMongo } from './permissionSetActions';

const permissionSetSlice = createSlice({
  name: 'permissionSet',
  initialState: {
    permissionSetData: [],
    permissionSetDataFromMongo:[],
    loading: 'idle',
    loadingfromMongo:'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissionSetData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchPermissionSetData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.permissionSetData = action.payload;
      })
      .addCase(fetchPermissionSetData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchPermissionSetDataFromMongo.pending, (state) => {
        state.loadingfromMongo = 'pending';
      })
      .addCase(fetchPermissionSetDataFromMongo.fulfilled, (state, action) => {
        state.loadingfromMongo = 'fulfilled';
        state.permissionSetDataFromMongo = action.payload;
      })
      .addCase(fetchPermissionSetDataFromMongo.rejected, (state, action) => {
        state.loadingfromMongo = 'rejected';
        state.error = action.error.message;
      })
  },
});

export default permissionSetSlice.reducer;

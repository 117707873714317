import { createSlice } from '@reduxjs/toolkit';
import { fetchTestCoverageData } from './testCoverageAction';

const testCoverageSlice = createSlice({
  name: 'testQuality',
  initialState: {
    testQualitylist: [],
    loading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestCoverageData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchTestCoverageData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.testQualitylist = action.payload;
      })
      .addCase(fetchTestCoverageData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default testCoverageSlice.reducer;

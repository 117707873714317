import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchLwcAuraData = createAsyncThunk('lwcAura/fetchLwcAuraData', async () => {
  const guid=window.sessionStorage.getItem('guid');
  const response = await fetch(
    ` /v1/salesforce/codecoverage/lwc/aura/${guid}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
  
      },
    }
  );
  const data = await response.json();
  return data;
  
});

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchPageLayoutData = createAsyncThunk(
  "pageLayout/fetchPageLayoutData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
      const response = await fetch(
        ` /v1/salesforce/pagelayout/fetch/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            gid:gid,
            at:at,
            iu:iu
          },
        }
      );
      const data = await response.json();
  
      // console.log('fetchValidationRuleData',data);
      return data;
    }
  
);

export const fetchPageLayoutDataFromMongo = createAsyncThunk(
  "pageLayout/fetchPageLayoutDataFromMongo",
  async () => {
    const guid=window.sessionStorage.getItem('guid');
      const response = await fetch(
        ` /v1/salesforce/pagelayout/${guid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
  
      // console.log('fetchValidationRuleData',data);
      return data;
    }
  
);

import { createSlice } from '@reduxjs/toolkit';
import {fetchLeadData} from './LeadCreateAction';

const LeadSlice = createSlice({
    name: 'Lead',
    initialState: {
        LeadData: [],
        loading: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeadData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchLeadData.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.LeadData = action.payload;
                state.error = null; // Reset error state upon success
            })
            .addCase(fetchLeadData.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            });
    },
});

export default LeadSlice.reducer;
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchRecordTypeData = createAsyncThunk(
  "recordType/fetchRecordTypeData",
  async () => {
    const gid=window.sessionStorage.getItem('guid');
    const at=window.sessionStorage.getItem("accessToken");
    const iu=window.sessionStorage.getItem("instaceurl");
      const response = await fetch(
        ` /v1/salesforce/recordtype/fetch/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            gid:gid,
            at:at,
            iu:iu
          },
        }
      );
      const data = await response.json();
      // console.log('fetchRecordTypeData',data);
      return data;
    }
  
);
export const fetchRecordTypeDataFromMongo = createAsyncThunk(
  "recordType/fetchRecordTypeDataFromMongo",
  async () => {
    const guid=window.sessionStorage.getItem('guid');
      const response = await fetch(
        ` /v1/salesforce/recordtype/${guid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
      
          },
        }
      );
      const data = await response.json();
      // console.log('fetchRecordTypeData',data);
      return data;
    }
  
);

import { createSlice } from '@reduxjs/toolkit';
import { fetchReportData,fetchReportDataFromMongo } from './reportAction';

const reportSlice = createSlice({
  name: 'report',
  initialState: {
    reportListData: [],
    reportDataFromMongo:[],
    loading: 'idle',
    loadingMongo:'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchReportData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.reportListData = action.payload;
      })
      .addCase(fetchReportData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchReportDataFromMongo.pending, (state) => {
        state.loadingMongo = 'pending';
      })
      .addCase(fetchReportDataFromMongo.fulfilled, (state, action) => {
        state.loadingMongo = 'fulfilled';
        state.reportDataFromMongo = action.payload;
      })
      .addCase(fetchReportDataFromMongo.rejected, (state, action) => {
        state.loadingMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default reportSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { fetchSecurityData, fetchSecurityDataFromMongo } from './securityAction';

const securitySlice = createSlice({
  name: 'security',
  initialState: {
    securityList: [],
    securityListFromMongo: [],
    loadingSecurityData: 'idle',
    loadingSecurityMongo: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSecurityData.pending, (state) => {
        state.loadingSecurityData = 'pending';
      })
      .addCase(fetchSecurityData.fulfilled, (state, action) => {
        state.loadingSecurityData = 'fulfilled';
        state.securityList = action.payload;
      })
      .addCase(fetchSecurityData.rejected, (state, action) => {
        state.loadingSecurityData = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchSecurityDataFromMongo.pending, (state) => {
        state.loadingSecurityMongo = 'pending';
      })
      .addCase(fetchSecurityDataFromMongo.fulfilled, (state, action) => {
        state.loadingSecurityMongo = 'fulfilled';
        state.securityListFromMongo = action.payload;
      })
      .addCase(fetchSecurityDataFromMongo.rejected, (state, action) => {
        state.loadingSecurityMongo = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default securitySlice.reducer;
